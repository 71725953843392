<template>
  <div class="decoration-guide-dialog" v-loading="dialogLoading">
    <div class="scroll-view">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        ref="ruleForm"
        label-width="100px"
      >
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item label="业务ID" prop="id">
              {{ ruleForm.id || "-" }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否显示在小程序" prop="showApplet">
              <el-switch
                v-model="ruleForm.showApplet"
                active-text="是"
                active-color="#1A4CEC"
                active-value="true"
                inactive-value="false"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务阶段" prop="businessStage">
              <el-select style="width: 100%" v-model="ruleForm.businessStage">
                <el-option
                  v-for="(item, index) in businessStageList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务名称" prop="businessName">
              <el-input
                maxlength="15"
                v-model="ruleForm.businessName"
                placeholder="请输入业务名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序号" prop="sort">
              <el-input-number
                v-model="ruleForm.sort"
                placeholder="请输入序号"
                :controls="false"
                :min="1"
                :max="1000"
                style="width: 100%"
              ></el-input-number>
              <!-- <el-input
                v-model="ruleForm.sort"
                placeholder="请输入序号"
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务类型" prop="businessNeed">
              <el-select style="width: 100%" v-model="ruleForm.businessNeed">
                <el-option
                  v-for="(item, index) in businessNeedList"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="办理流程" prop="bllcList">
              <div class="table-header-line">
                <span class="flex-1"> 序号 </span>
                <span class="flex-2"> 小标题 </span>
                <span class="flex-4 require-item"> 内容 </span>
                <span class="flex-3"> 操作 </span>
              </div>
              <div v-if="ruleForm.bllcList.length">
                <div
                  class="table-detail-line"
                  v-for="(item, index) in ruleForm.bllcList"
                  :key="index"
                >
                  <span class="flex-1">
                    {{ item.sort }}
                  </span>
                  <span class="flex-2">
                    <el-input
                      v-if="item.editFlag"
                      v-model="item.title"
                    ></el-input>
                    <span v-else>{{ item.title }}</span>
                  </span>
                  <span class="flex-4">
                    <el-input
                      v-if="item.editFlag"
                      v-model="item.content"
                    ></el-input>
                    <span v-else>{{ item.content }}</span>
                  </span>
                  <span class="flex-3">
                    <el-button
                      type="text"
                      @click="positionChangeHandler('up', 'bllcList', index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="positionChangeHandler('down', 'bllcList', index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      v-if="!item.editFlag"
                      @click="lineStateChange('bllcList', index, true)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      v-if="item.editFlag"
                      @click="lineStateChange('bllcList', index, false)"
                      >保存</el-button
                    >
                    <el-button
                      type="text"
                      class="danger-button"
                      @click="deleteLine('bllcList', index)"
                      >删除</el-button
                    >
                  </span>
                </div>
              </div>
              <div v-else class="no-data-container">暂无数据...</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              class="margin-bottom-20"
              @click="addEmptyLine('bllcList')"
            >
              添加流程
            </el-button>
          </el-col>
          <el-col :span="24">
            <el-form-item label="其他要求" prop="qtyqList">
              <div class="table-header-line">
                <span class="flex-1"> 序号 </span>
                <span class="flex-2"> 小标题 </span>
                <span class="flex-4 require-item"> 内容 </span>
                <span class="flex-3"> 操作 </span>
              </div>
              <div v-if="ruleForm.qtyqList.length">
                <div
                  class="table-detail-line"
                  v-for="(item, index) in ruleForm.qtyqList"
                  :key="index"
                >
                  <span class="flex-1">
                    {{ item.sort }}
                  </span>
                  <span class="flex-2">
                    <el-input
                      v-if="item.editFlag"
                      v-model="item.title"
                    ></el-input>
                    <span v-else>{{ item.title }}</span>
                  </span>
                  <span class="flex-4">
                    <el-input
                      v-if="item.editFlag"
                      v-model="item.content"
                    ></el-input>
                    <span v-else>{{ item.content }}</span>
                  </span>
                  <span class="flex-3">
                    <el-button
                      type="text"
                      @click="positionChangeHandler('up', 'qtyqList', index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="positionChangeHandler('down', 'qtyqList', index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      v-if="!item.editFlag"
                      @click="lineStateChange('qtyqList', index, true)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      v-if="item.editFlag"
                      @click="lineStateChange('qtyqList', index, false)"
                      >保存</el-button
                    >
                    <el-button
                      type="text"
                      class="danger-button"
                      @click="deleteLine('qtyqList', index)"
                      >删除</el-button
                    >
                  </span>
                </div>
              </div>
              <div v-else class="no-data-container">暂无数据...</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              class="margin-bottom-20"
              @click="addEmptyLine('qtyqList')"
            >
              添加要求
            </el-button>
          </el-col>
          <el-col :span="24">
            <el-form-item label="其他要求说明" prop="qtyqsmList">
              <el-input v-model="ruleForm.otherRequirements"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="申请材料" prop="sqclList">
              <div class="table-header-line">
                <span class="flex-1"> 序号 </span>
                <span class="flex-2 require-item"> 文件名称 </span>
                <span class="flex-2 require-item"> 文件附件 </span>
                <span class="flex-3"> 操作 </span>
              </div>
              <div v-if="ruleForm.sqclList.length">
                <div
                  class="table-detail-line"
                  v-for="(item, index) in ruleForm.sqclList"
                  :key="index"
                >
                  <span class="flex-1">
                    {{ item.sort }}
                  </span>
                  <span class="flex-2">
                    {{ item.title }}
                  </span>
                  <span class="flex-2">
                    {{ item.content }}
                  </span>
                  <span class="flex-3">
                    <el-button
                      type="text"
                      @click="positionChangeHandler('up', 'sqclList', index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="positionChangeHandler('down', 'sqclList', index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="
                        item.enableFlag =
                          item.enableFlag === 'false' ? 'true' : 'false'
                      "
                      >{{
                        item.enableFlag === "true" ? "禁用" : "启用"
                      }}</el-button
                    >
                    <el-button type="text" @click="downloadHandler(item.fileId)"
                      >下载</el-button
                    >
                    <!-- <el-button type="text">下载历史记录</el-button> -->
                    <el-button
                      type="text"
                      class="danger-button"
                      @click="deleteLine('sqclList', index)"
                      >删除</el-button
                    >
                  </span>
                </div>
              </div>
              <div v-else class="no-data-container">暂无数据...</div>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-loading="uploadLoading">
            <el-upload
              class="avatar-uploader"
              :on-success="uploadSuccess"
              :on-error="uploadFail"
              :before-upload="beforeUpload"
              :show-file-list="false"
              :with-credentials="true"
              :accept="'.pdf, .xls, .xlsx, .ppt, .pptx, .doc, .docx'"
              :action="uploadPath"
            >
              <el-button type="primary" size="small" class="margin-bottom-20">
                添加文件
              </el-button>
            </el-upload>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="bzList">
              <div class="table-header-line">
                <span class="flex-1"> 序号 </span>
                <span class="flex-2"> 小标题 </span>
                <span class="flex-4 require-item"> 内容 </span>
                <span class="flex-2"> 操作 </span>
              </div>
              <div v-if="ruleForm.bzList.length">
                <div
                  class="table-detail-line"
                  v-for="(item, index) in ruleForm.bzList"
                  :key="index"
                >
                  <span class="flex-1">
                    {{ item.sort }}
                  </span>
                  <span class="flex-2">
                    <el-input
                      v-if="item.editFlag"
                      v-model="item.title"
                    ></el-input>
                    <span v-else>{{ item.title }}</span>
                  </span>
                  <span class="flex-4">
                    <el-input
                      v-if="item.editFlag"
                      v-model="item.content"
                    ></el-input>
                    <span v-else>{{ item.content }}</span>
                  </span>
                  <span class="flex-3">
                    <el-button
                      type="text"
                      @click="positionChangeHandler('up', 'bzList', index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="positionChangeHandler('down', 'bzList', index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      v-if="!item.editFlag"
                      @click="lineStateChange('bzList', index, true)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      v-if="item.editFlag"
                      @click="lineStateChange('bzList', index, false)"
                      >保存</el-button
                    >
                    <el-button
                      type="text"
                      class="danger-button"
                      @click="deleteLine('bzList', index)"
                      >删除</el-button
                    >
                  </span>
                </div>
              </div>
              <div v-else class="no-data-container">暂无数据...</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              @click="addEmptyLine('bzList')"
            >
              添加备注
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="button-container">
      <el-button @click="$emit('close', false)">取消</el-button>
      <el-button type="primary" @click="saveHander">{{
        id ? "保存" : "添加"
      }}</el-button>
    </div>
  </div>
</template>

<script>
const EmptyLineMap = {
  bllcList: {
    sort: 1,
    title: "",
    content: "",
    enableFlag: true,
    editFlag: true,
  },
  qtyqList: {
    sort: 1,
    title: "",
    content: "",
    enableFlag: true,
    editFlag: true,
  },
  bzList: {
    sort: 1,
    title: "",
    content: "",
    enableFlag: true,
    editFlag: true,
  },
};
import { deepClone } from "@/utils/utils.js";
import { envInfo } from "@/constants/envInfo";
import {
  addGuide,
  editGuide,
  getDetailById,
  deleteSubItem,
} from "@/api/ruge/gsPark/customerService/docurationGuide.js";
import { downloadServiceGuide } from "@/api/ruge/gsPark/customerService/serviceGuide";
export default {
  name: "decorationGuideDialog",
  props: {
    businessStageList: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: Number | String,
      default() {
        return "";
      },
    },
  },
  created() {
    this.id && this.initDetailDatas();
  },
  data() {
    const bllcListValid = (rule, value, callback) => {
      for (let i = 0; i < this.ruleForm.bllcList.length; i++) {
        if (!this.ruleForm.bllcList[i].content) {
          callback(new Error("请填写办理流程的内容"));
        }
      }
      if (!this.ruleForm.bllcList.length) {
        callback(new Error("请至少添加一条办理流程"));
      } else {
        callback();
      }
    };
    return {
      dialogLoading: false,
      uploadLoading: false,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      businessNeedList: [
        {
          label: "必需",
          value: "必需",
        },
        {
          label: "按需",
          value: "按需",
        },
      ],
      ruleForm: {
        id: "",
        type: 1,
        showApplet: "true",
        businessStage: "",
        businessName: "",
        sort: "",
        businessNeed: "",
        otherRequirements: "",
        // 办理流程
        bllcList: [],
        // 其他要求
        qtyqList: [],
        // 申请材料
        sqclList: [],
        // 备注
        bzList: [],
      },
      rules: {
        businessStage: [
          { required: true, message: "请选择业务阶段", trigger: "blur" },
        ],
        businessName: [
          { required: true, message: "请输入业务名称", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入序号", trigger: "blur" }],
        businessNeed: [
          { required: true, message: "请选择业务类型", trigger: "blur" },
        ],
        bllcList: {
          required: true,
          trigger: ["blur", "change"],
          validator: bllcListValid,
        },
      },
    };
  },
  methods: {
    initDetailDatas() {
      this.dialogLoading = true;
      getDetailById({ id: this.id })
        .then((res) => {
          const dispalyDatas = this.displayHandler(res.data);
          this.ruleForm = { ...this.ruleForm, ...dispalyDatas };
          console.log("this.ruleForm", this.ruleForm);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    displayHandler(datas) {
      let result = { ...datas };
      const { guideMap } = datas;
      // 办理流程
      if (guideMap.hasOwnProperty("办理流程")) {
        result.bllcList = guideMap["办理流程"];
      }
      // 其他要求
      if (guideMap.hasOwnProperty("其他要求")) {
        result.qtyqList = guideMap["其他要求"];
      }
      // 其他要求说明
      if (guideMap.hasOwnProperty("其他要求说明")) {
        result.otherRequirements = guideMap["其他要求说明"][0].content;
        result.otherRequirementsId = guideMap["其他要求说明"][0].id;
      }
      // 申请材料
      if (guideMap.hasOwnProperty("申请材料")) {
        result.sqclList = guideMap["申请材料"];
      }
      // 备注
      if (guideMap.hasOwnProperty("备注")) {
        result.bzList = guideMap["备注"];
      }
      return result;
    },
    saveHander() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          const params = this.buildFormMap();
          if (this.id) {
            // 更新
            editGuide(params)
              .then(({ code }) => {
                if (code === 200) {
                  this.$message.success("修改指引成功！");
                  this.$emit("close", true);
                }
              })
              .finally(() => {
                this.dialogLoading = false;
              });
          } else {
            // 新增
            console.log("params", params);
            addGuide(params)
              .then(({ code }) => {
                if (code === 200) {
                  this.$message.success("新增指引成功！");
                  this.$emit("close", true);
                }
              })
              .finally(() => {
                this.dialogLoading = false;
              });
          }
        }
      });
    },
    buildFormMap() {
      const requestDatas = deepClone(this.ruleForm);
      requestDatas.guideMap = {};
      if (requestDatas.bllcList.length) {
        requestDatas.guideMap["办理流程"] = requestDatas.bllcList.map(
          (item) => {
            return {
              id: item.id,
              title: item.title,
              content: item.content,
              sort: item.sort,
              enableFlag: item.enableFlag,
            };
          }
        );
      }
      if (requestDatas.qtyqList.length) {
        requestDatas.guideMap["其他要求"] = requestDatas.qtyqList.map(
          (item) => {
            return {
              id: item.id,
              title: item.title,
              content: item.content,
              sort: item.sort,
              enableFlag: item.enableFlag,
            };
          }
        );
      }
      requestDatas.guideMap["其他要求说明"] = [
        {
          content: requestDatas.otherRequirements,
          sort: 1,
          enableFlag: true,
          id: requestDatas.otherRequirementsId || "",
        },
      ];
      if (requestDatas.sqclList.length) {
        requestDatas.guideMap["申请材料"] = requestDatas.sqclList.map(
          (item) => {
            return {
              id: item.id,
              title: item.title,
              content: item.content,
              sort: item.sort,
              enableFlag: String(item.enableFlag),
              fileId: item.fileId,
            };
          }
        );
      }
      if (requestDatas.bzList.length) {
        requestDatas.guideMap["备注"] = requestDatas.bzList.map((item) => {
          return {
            id: item.id,
            title: item.title,
            content: item.content,
            sort: item.sort,
            enableFlag: item.enableFlag,
          };
        });
      }
      delete requestDatas.bllcList;
      delete requestDatas.qtyqList;
      delete requestDatas.sqclList;
      delete requestDatas.bzList;
      return requestDatas;
    },
    downloadHandler(fileId) {
      //下载文件
      const url =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fi=" +
        fileId;
      const asemName = "txt";
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", asemName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      downloadServiceGuide({ id: fileId });
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
      this.uploadLoading = false;
    },
    beforeUpload(file) {
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        this.uploadLoading = false;
        return false;
      }
    },
    uploadSuccess(files) {
      const { displayName, fileId } = files[0];
      let str = displayName.split(".");
      str.length = str.length - 1;
      let targetStr = str.join(".");
      this.ruleForm.sqclList.push({
        sort: 1,
        title: targetStr,
        content: displayName,
        fileId: fileId,
        enableFlag: true,
      });
      this.resetListIndex("sqclList");
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
      this.uploadLoading = false;
    },
    positionChangeHandler(type, dataList, index) {
      if (type === "up" && index === 0) {
        this.$message.warning("已经是第一条啦！");
        return;
      }
      if (type === "down" && index === this.ruleForm[dataList].length - 1) {
        this.$message.warning("已经是最后条啦！");
        return;
      }
      const targetIndex = type === "up" ? index - 1 : index + 1;
      this.ruleForm[dataList][index] = this.ruleForm[dataList].splice(
        targetIndex,
        1,
        this.ruleForm[dataList][index]
      )[0];
      this.resetListIndex(dataList);
    },
    lineStateChange(dataList, index, state) {
      if (!state && !this.ruleForm[dataList][index].content) {
        this.$message.warning("请填写内容");
        return;
      }
      this.$set(this.ruleForm[dataList][index], "editFlag", state);
      // this.ruleForm[dataList][index].editFlag = state;
    },
    async deleteLine(dataList, index) {
      // 如果subitem有id的话，调接口删除
      const subItemId = this.ruleForm[dataList][index].id;
      if (subItemId) {
        await deleteSubItem({ id: subItemId });
      }
      this.ruleForm[dataList].splice(index, 1);
      this.resetListIndex(dataList);
    },
    resetListIndex(dataList) {
      setTimeout(() => {
        this.ruleForm[dataList].forEach((item, index) => {
          item.sort = index + 1;
        });
      }, 50);
      this.$nextTick(() => {});
    },
    addEmptyLine(dataList) {
      this.ruleForm[dataList].push(deepClone(EmptyLineMap[dataList]));
      this.resetListIndex(dataList);
    },
  },
};
</script>

<style lang="less" scoped>
.decoration-guide-dialog {
  .scroll-view {
    // 给滚动条让出位置
    margin-right: -30px;
    padding-right: 30px;
  }
  .button-container {
    margin-top: 20px;
    height: 50px;
    line-height: 50px;
    text-align: right;
  }
  .table-header-line {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #2a4158;
    border-bottom: 1px solid #ebeef5;
  }
  .table-detail-line {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    border-bottom: 1px solid #ebeef5;
    padding: 5px 0;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span + span {
      margin-right: 5px;
    }
  }
  .no-data-container {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ebeef5;
    text-align: center;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  .flex-4 {
    flex: 4;
  }
  .margin-bottom-20 {
    margin-bottom: 20px;
  }
  .require-item {
    &::before {
      content: "*";
      color: #ff0000;
      display: inline-block;
    }
  }
  .danger-button {
    color: #ff0000;
  }
  ::v-deep .el-input__inner {
    text-align: left;
  }
}
</style>