import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 装修指引 - 分页查询
 * @param params
 */
export function getGuideList(params) {
  return request({
    url: envInfo.bgApp.customerServiceGSPath + '/appletGuide/queryList',
    method: 'get',
    params,
  });
}

/**
 * 装修指引 - 新增
 * @param params
 */
export function addGuide(params) {
  return request({
    url: envInfo.bgApp.customerServiceGSPath + '/appletGuide/create',
    method: 'post',
    data: params,
  });
}

/**
 * 装修指引 - 编辑
 * @param params
 */
export function editGuide(params) {
  return request({
    url: envInfo.bgApp.customerServiceGSPath + '/appletGuide/edit',
    method: 'post',
    data: params,
  });
}

/**
 * 办事指南 - 单项删除
 * @param params
 */
export function deleteSubItem(params) {
  return request({
    url: envInfo.bgApp.customerServiceGSPath + '/appletGuide/deleteGuideSub',
    method: 'post',
    data: params,
  });
}

/**
 * 装修指引 - 删除
 * @param params
 */
export function deleteGuide(params) {
  return request({
    url: envInfo.bgApp.customerServiceGSPath + '/appletGuide/delete',
    method: 'post',
    data: params,
  });
}

/**
 * 装修指引 - 获取详情
 * @param params
 */
export function getDetailById(params) {
  return request({
    url: envInfo.bgApp.customerServiceGSPath + '/appletGuide/getDetails',
    method: 'get',
    params,
  });
}
