var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dialogLoading,
          expression: "dialogLoading",
        },
      ],
      staticClass: "decoration-guide-dialog",
    },
    [
      _c(
        "div",
        { staticClass: "scroll-view" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "top",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务ID", prop: "id" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.ruleForm.id || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否显示在小程序",
                            prop: "showApplet",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": "是",
                              "active-color": "#1A4CEC",
                              "active-value": "true",
                              "inactive-value": "false",
                            },
                            model: {
                              value: _vm.ruleForm.showApplet,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "showApplet", $$v)
                              },
                              expression: "ruleForm.showApplet",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务阶段", prop: "businessStage" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.ruleForm.businessStage,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "businessStage", $$v)
                                },
                                expression: "ruleForm.businessStage",
                              },
                            },
                            _vm._l(
                              _vm.businessStageList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    value: item.value,
                                    label: item.label,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务名称", prop: "businessName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "15",
                              placeholder: "请输入业务名称",
                            },
                            model: {
                              value: _vm.ruleForm.businessName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "businessName", $$v)
                              },
                              expression: "ruleForm.businessName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "序号", prop: "sort" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请输入序号",
                              controls: false,
                              min: 1,
                              max: 1000,
                            },
                            model: {
                              value: _vm.ruleForm.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sort", $$v)
                              },
                              expression: "ruleForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务类型", prop: "businessNeed" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.ruleForm.businessNeed,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "businessNeed", $$v)
                                },
                                expression: "ruleForm.businessNeed",
                              },
                            },
                            _vm._l(
                              _vm.businessNeedList,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    value: item.value,
                                    label: item.label,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "办理流程", prop: "bllcList" } },
                        [
                          _c("div", { staticClass: "table-header-line" }, [
                            _c("span", { staticClass: "flex-1" }, [
                              _vm._v(" 序号 "),
                            ]),
                            _c("span", { staticClass: "flex-2" }, [
                              _vm._v(" 小标题 "),
                            ]),
                            _c("span", { staticClass: "flex-4 require-item" }, [
                              _vm._v(" 内容 "),
                            ]),
                            _c("span", { staticClass: "flex-3" }, [
                              _vm._v(" 操作 "),
                            ]),
                          ]),
                          _vm.ruleForm.bllcList.length
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.ruleForm.bllcList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "table-detail-line",
                                      },
                                      [
                                        _c("span", { staticClass: "flex-1" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.sort) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "flex-2" },
                                          [
                                            item.editFlag
                                              ? _c("el-input", {
                                                  model: {
                                                    value: item.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.title",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "flex-4" },
                                          [
                                            item.editFlag
                                              ? _c("el-input", {
                                                  model: {
                                                    value: item.content,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.content",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.content)),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "flex-3" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "up",
                                                      "bllcList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("上移")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "down",
                                                      "bllcList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("下移")]
                                            ),
                                            !item.editFlag
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.lineStateChange(
                                                          "bllcList",
                                                          index,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("编辑")]
                                                )
                                              : _vm._e(),
                                            item.editFlag
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.lineStateChange(
                                                          "bllcList",
                                                          index,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("保存")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "danger-button",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteLine(
                                                      "bllcList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", { staticClass: "no-data-container" }, [
                                _vm._v("暂无数据..."),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "margin-bottom-20",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.addEmptyLine("bllcList")
                            },
                          },
                        },
                        [_vm._v("\n            添加流程\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "其他要求", prop: "qtyqList" } },
                        [
                          _c("div", { staticClass: "table-header-line" }, [
                            _c("span", { staticClass: "flex-1" }, [
                              _vm._v(" 序号 "),
                            ]),
                            _c("span", { staticClass: "flex-2" }, [
                              _vm._v(" 小标题 "),
                            ]),
                            _c("span", { staticClass: "flex-4 require-item" }, [
                              _vm._v(" 内容 "),
                            ]),
                            _c("span", { staticClass: "flex-3" }, [
                              _vm._v(" 操作 "),
                            ]),
                          ]),
                          _vm.ruleForm.qtyqList.length
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.ruleForm.qtyqList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "table-detail-line",
                                      },
                                      [
                                        _c("span", { staticClass: "flex-1" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.sort) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "flex-2" },
                                          [
                                            item.editFlag
                                              ? _c("el-input", {
                                                  model: {
                                                    value: item.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.title",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "flex-4" },
                                          [
                                            item.editFlag
                                              ? _c("el-input", {
                                                  model: {
                                                    value: item.content,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.content",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.content)),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "flex-3" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "up",
                                                      "qtyqList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("上移")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "down",
                                                      "qtyqList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("下移")]
                                            ),
                                            !item.editFlag
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.lineStateChange(
                                                          "qtyqList",
                                                          index,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("编辑")]
                                                )
                                              : _vm._e(),
                                            item.editFlag
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.lineStateChange(
                                                          "qtyqList",
                                                          index,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("保存")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "danger-button",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteLine(
                                                      "qtyqList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", { staticClass: "no-data-container" }, [
                                _vm._v("暂无数据..."),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "margin-bottom-20",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.addEmptyLine("qtyqList")
                            },
                          },
                        },
                        [_vm._v("\n            添加要求\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "其他要求说明", prop: "qtyqsmList" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.otherRequirements,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "otherRequirements", $$v)
                              },
                              expression: "ruleForm.otherRequirements",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请材料", prop: "sqclList" } },
                        [
                          _c("div", { staticClass: "table-header-line" }, [
                            _c("span", { staticClass: "flex-1" }, [
                              _vm._v(" 序号 "),
                            ]),
                            _c("span", { staticClass: "flex-2 require-item" }, [
                              _vm._v(" 文件名称 "),
                            ]),
                            _c("span", { staticClass: "flex-2 require-item" }, [
                              _vm._v(" 文件附件 "),
                            ]),
                            _c("span", { staticClass: "flex-3" }, [
                              _vm._v(" 操作 "),
                            ]),
                          ]),
                          _vm.ruleForm.sqclList.length
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.ruleForm.sqclList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "table-detail-line",
                                      },
                                      [
                                        _c("span", { staticClass: "flex-1" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.sort) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c("span", { staticClass: "flex-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.title) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c("span", { staticClass: "flex-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.content) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "flex-3" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "up",
                                                      "sqclList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("上移")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "down",
                                                      "sqclList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("下移")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    item.enableFlag =
                                                      item.enableFlag ===
                                                      "false"
                                                        ? "true"
                                                        : "false"
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.enableFlag === "true"
                                                      ? "禁用"
                                                      : "启用"
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downloadHandler(
                                                      item.fileId
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("下载")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "danger-button",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteLine(
                                                      "sqclList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", { staticClass: "no-data-container" }, [
                                _vm._v("暂无数据..."),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.uploadLoading,
                          expression: "uploadLoading",
                        },
                      ],
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "on-success": _vm.uploadSuccess,
                            "on-error": _vm.uploadFail,
                            "before-upload": _vm.beforeUpload,
                            "show-file-list": false,
                            "with-credentials": true,
                            accept:
                              ".pdf, .xls, .xlsx, .ppt, .pptx, .doc, .docx",
                            action: _vm.uploadPath,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "margin-bottom-20",
                              attrs: { type: "primary", size: "small" },
                            },
                            [_vm._v("\n              添加文件\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "bzList" } },
                        [
                          _c("div", { staticClass: "table-header-line" }, [
                            _c("span", { staticClass: "flex-1" }, [
                              _vm._v(" 序号 "),
                            ]),
                            _c("span", { staticClass: "flex-2" }, [
                              _vm._v(" 小标题 "),
                            ]),
                            _c("span", { staticClass: "flex-4 require-item" }, [
                              _vm._v(" 内容 "),
                            ]),
                            _c("span", { staticClass: "flex-2" }, [
                              _vm._v(" 操作 "),
                            ]),
                          ]),
                          _vm.ruleForm.bzList.length
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.ruleForm.bzList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "table-detail-line",
                                      },
                                      [
                                        _c("span", { staticClass: "flex-1" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.sort) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "flex-2" },
                                          [
                                            item.editFlag
                                              ? _c("el-input", {
                                                  model: {
                                                    value: item.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.title",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "flex-4" },
                                          [
                                            item.editFlag
                                              ? _c("el-input", {
                                                  model: {
                                                    value: item.content,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "content",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.content",
                                                  },
                                                })
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.content)),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "flex-3" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "up",
                                                      "bzList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("上移")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.positionChangeHandler(
                                                      "down",
                                                      "bzList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("下移")]
                                            ),
                                            !item.editFlag
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.lineStateChange(
                                                          "bzList",
                                                          index,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("编辑")]
                                                )
                                              : _vm._e(),
                                            item.editFlag
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.lineStateChange(
                                                          "bzList",
                                                          index,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("保存")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "danger-button",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteLine(
                                                      "bzList",
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("div", { staticClass: "no-data-container" }, [
                                _vm._v("暂无数据..."),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.addEmptyLine("bzList")
                            },
                          },
                        },
                        [_vm._v("\n            添加备注\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveHander } },
            [_vm._v(_vm._s(_vm.id ? "保存" : "添加"))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }